function App() {
  return (
    <div>
      <h1>Orange Sprots</h1>
      <p>Orange County, NC Women's Senior Basketball (50+)</p>
      <p>Website coming soon!</p>
    </div>
  )
}

export default App
